<template>
  <div class="dizme_tm_section" id="home">
    <div class="dizme_tm_hero">
      <div
        class="background"
        :data-img-url="`/img/slider/${dark ? 2 : 1}.jpg`"
      ></div>
      <div class="container">
        <div class="content">
          <div class="details">
            <div class="name">
              <h3>Tetiana Iliushchenko</h3>
            </div>
            <div class="text">
              <p>

                  Hello, I'm a creative designer with extensive experience in industrial packaging and advertising product design. Throughout my career, I've had the privilege of working across diverse industries, developing innovative packaging solutions that ensure both product safety and visual appeal, as well as crafting advertising materials that effectively capture the essence of brands and products.
              </p>
            </div>
            <div class="button">
              <div class="dizme_tm_button">
                <a class="anchor" href="#about"><span>About Me</span></a>
              </div>
              <div class="social">
                <ul>
                  <li>
                    <a href="https://www.linkedin.com/in/tatyana-iljushenko-218953271/" target="_blank">
                      <img src="/img/logo/logo_linkedin.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.upwork.com/freelancers/~011efca8e118df5152" target="_blank">
                      <img src="/img/logo/logo_upwork.png" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="avatar">
            <div class="image">
              <img src="/img/slider/avataar.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="dizme_tm_down">
        <a class="anchor" href="#process" v-html="dark ? mouseDark : mouse">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ai, figma, mouse, mouseDark, ps } from "../svg";
export default {
  name: "HomeComponent",
  data() {
    return { ai, ps, figma, mouse, mouseDark };
  },
  props: {
    dark: { type: Boolean },
  },
};
</script>
