<template>
  <div class="dizme_tm_section" id="contact">
    <div class="dizme_tm_contact">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>Contact Me</span>
          <h3>I'll be happy to answer your questions</h3>
          <p>
            Please fill out the form on this section to contact with me.
          </p>
        </div>
        <div class="contact_inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <ul>
              <li>
                <div class="list_inner">
                  <div class="icon orangeBackground">
                    <i class="icon-location orangeText"></i>
                  </div>
                  <div class="short">
                    <h3>Address</h3>
                    <span>Piaseczno, Poland</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <div class="icon greenBackground">
                    <i class="icon-mail-1 greenText"></i>
                  </div>
                  <div class="short">
                    <h3>Email</h3>
                    <span><a href="#">contact@artimosaic.pl</a></span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <div class="fields">
                <form @submit.prevent="submitForm" action="/contact_form" method="post" class="contact_form" id="contact_form" autocomplete="off">
                <div
                  class="returnmessage"
                  data-success="Your message has been received, We will contact you soon."
                ></div>
                <div class="empty_notice">
                  <span>Please Fill Required Fields</span>
                </div>
                <div class="input_list">
                  <ul>
                      <li>
                          <input id="email" v-model="email" type="text" placeholder="Your Email" />
                      </li>
                      <li>
                          <input id="subject" v-model="subject" type="text" placeholder="Subject" />
                      </li>
                  </ul>
                </div>
                <div class="message_area">
                    <textarea id="message" v-model="message" placeholder="Write your message here"></textarea>
                    <span class="google_terms">This site is protected by reCAPTCHA and the Google
                    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                        <a href="https://policies.google.com/terms">Terms of Service</a> apply.</span>
                </div>
                  <div class="dizme_tm_button">
                      <button type="submit"><span>Submit Now</span></button>
                  </div>
                  <span class="error_message" v-html="emailerror"></span>
                  <span class="result_message" v-html="emailresult"></span>
              </form>
            </div>
          </div>
          <div class="brush_2 wow fadeInRight" data-wow-duration="1s">
            <img src="img/brushes/contact/2.png" alt="" />
          </div>
        </div>
        <div class="dizme_tm_map wow fadeInUp" data-wow-duration="1s">
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                width="100%"
                height="375"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=Piaseczno%2005-500&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe
              ><a
                href="https://www.embedgooglemap.net/blog/divi-discount-code-elegant-themes-coupon"
              ></a>
            </div>
          </div>

          <!-- Get your API here https://www.embedgooglemap.net -->
        </div>
      </div>
      <div class="brush_1 wow fadeInLeft" data-wow-duration="1s">
        <img src="img/brushes/contact/1.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
export default {
  name: "ContactComponent",
    methods: {
         submitForm() {
            // Execute reCAPTCHA verification
             window.grecaptcha.ready(() => {
                 window.grecaptcha.execute('6LdkOCwpAAAAAAudOySHbY9x8Nb7nx9yKGpX5cdy', { action: 'submit' })
                    .then(token => {
                        // Pass the token to your backend for verification
                        this.sendForm(token);
                    });
            });
        },

        async sendForm(token) {
            // Access form data from the data properties
            const {email, subject, message} = this;

            const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;

            // Make a POST request to your backend using Axios
            const response = await axios.post('/contact_form', {
                email,
                subject,
                message,
                csrfToken,
                token
            });

            if(!response.data.error){
                this.resetForm();
                this.emailerror = '';
                this.emailresult = response.data.result;
            } else {
                this.emailerror = response.data.error;
                this.emailresult = '';
            }
        },
        resetForm() {
            // Reset form fields to their initial state by updating data properties
            this.email = '';
            this.subject = '';
            this.message = '';
        }
    },
  data() {
    return {
        email: '',
        subject: '',
        message: '',
        emailerror: '',
        emailresult: ''
    };
  },
};
</script>
