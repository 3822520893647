<template>
  <div class="dizme_tm_section" id="portfolio">
    <div class="dizme_tm_portfolio">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>Portfolio</span>
          <h3>Some of my success projects</h3>
          <p>
              Just a small part of the projects I have completed to demonstrate my skills and successful experience
          </p>
        </div>
        <div class="portfolio_filter">
          <ul>
            <li>
              <a
                class="c-pointer"
                :class="activeNav === '1' ? 'current' : ''"
                @click.prevent="() => (this.activeNav = '1')"
                data-filter="*"
                >All</a
              >
            </li>
            <li>
              <a
                class="c-pointer"
                :class="activeNav === '2' ? 'current' : ''"
                @click.prevent="() => (this.activeNav = '2')"
                data-filter=".package"
                >Package</a
              >
            </li>
            <li>
              <a
                class="c-pointer"
                :class="activeNav === '3' ? 'current' : ''"
                @click.prevent="() => (this.activeNav = '3')"
                data-filter=".labels"
                >Labels</a
              >
            </li>
            <li>
              <a
                class="c-pointer"
                :class="activeNav === '4' ? 'current' : ''"
                @click.prevent="() => (this.activeNav = '4')"
                data-filter=".advertising"
                >Advertising</a
              >
            </li>
          </ul>
        </div>
        <div class="dizme_tm_portfolio_titles"></div>
        <div class="portfolio_list wow fadeInUp" data-wow-duration="1s">
          <ul class="gallery_zoom grid">
            <li class="package grid-item">
              <div class="inner">
                <div
                    class="entry dizme_tm_portfolio_animation_wrap"
                    data-title="Grilled chicken stripes"
                    data-category="Package design"
                >
                  <a
                      class="portfolio_popup"
                      href="#"
                      @click.prevent=""
                  >
                    <img src="/img/portfolio/creative_salads/preview.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/creative_salads/preview.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Grilled chicken stripes</h3>
                  <span>Package design</span>
                </div>
              </div>
            </li>
            <li class="package labels grid-item">
              <div class="inner">
                <div
                    class="entry dizme_tm_portfolio_animation_wrap"
                    data-title="Hemp oil"
                    data-category="Label, Cardboard package"
                >
                  <a
                      class="portfolio_popup"
                      href="#"
                      @click.prevent=""
                  >
                    <img src="/img/portfolio/green_world/preview.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/green_world/preview.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Hemp oil</h3>
                  <span>Label, Cardboard package</span>
                </div>
              </div>
            </li>
            <li class="package grid-item">
              <div class="inner">
                <div
                    class="entry dizme_tm_portfolio_animation_wrap"
                    data-title="Oat cookies"
                    data-category="Package design"
                >
                  <a
                      class="portfolio_popup"
                      href="#"
                      @click.prevent=""
                  >
                    <img src="/img/portfolio/home_bakkery/preview.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/home_bakkery/preview.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Oat cookies</h3>
                  <span>Package design</span>
                </div>
              </div>
            </li>
            <li class="labels grid-item">
              <div class="inner">
                <div
                    class="entry dizme_tm_portfolio_animation_wrap"
                    data-title="Set of sauces"
                    data-category="Label design"
                >
                  <a
                      class="portfolio_popup"
                      href="#"
                      @click.prevent=""
                  >
                    <img src="/img/portfolio/paramount/preview.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/paramount/preview.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Set of sauces</h3>
                  <span>Label design</span>
                </div>
              </div>
            </li>
            <li class="labels grid-item">
              <div class="inner">
                <div
                    class="entry dizme_tm_portfolio_animation_wrap"
                    data-title="Dietary supplement"
                    data-category="Label design"
                >
                  <a
                      class="portfolio_popup"
                      href="#"
                      @click.prevent=""
                  >
                    <img src="/img/portfolio/ivl/preview.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/ivl/preview.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Dietary supplement</h3>
                  <span>Label design</span>
                </div>
              </div>
            </li>
            <li class="advertising grid-item">
              <div class="inner">
                <div
                    class="entry dizme_tm_portfolio_animation_wrap"
                    data-title="Corporate product catalog"
                    data-category="Advertising design"
                >
                  <a
                      class="portfolio_popup"
                      href="#"
                      @click.prevent=""
                  >
                    <img src="/img/portfolio/okvin/preview.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/okvin/preview.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Corporate product catalog</h3>
                  <span>Advertising design</span>
                </div>
              </div>
            </li>
            <li class="labels grid-item">
              <div class="inner">
                <div
                    class="entry dizme_tm_portfolio_animation_wrap"
                    data-title="Date with almond in chocolate"
                    data-category="Label design"
                >
                  <a
                      class="portfolio_popup"
                      href="#"
                      @click.prevent=""
                  >
                    <img src="/img/portfolio/al_akbar/preview.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/al_akbar/preview.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Date with almond in chocolate</h3>
                  <span>Label design</span>
                </div>
              </div>
            </li>
            <li class="package grid-item">
              <div class="inner">
                <div
                    class="entry dizme_tm_portfolio_animation_wrap"
                    data-title="Set of hygiene products"
                    data-category="Package design"
                >
                  <a
                      class="portfolio_popup"
                      href="#"
                      @click.prevent=""
                  >
                    <img src="/img/portfolio/mirax/preview.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/mirax/preview.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Set of hygiene products</h3>
                  <span>Package design</span>
                </div>
              </div>
            </li>
            <li class="package grid-item">
              <div class="inner">
                <div
                    class="entry dizme_tm_portfolio_animation_wrap"
                    data-title="Plantain chips"
                    data-category="Package design"
                >
                  <a
                      class="portfolio_popup"
                      href="#"
                      @click.prevent=""
                  >
                    <img src="/img/portfolio/harnutri/preview.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/harnutri/preview.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Plantain chips</h3>
                  <span>Package design</span>
                </div>
              </div>
            </li>
            <li class="labels grid-item">
              <div class="inner">
                <div
                    class="entry dizme_tm_portfolio_animation_wrap"
                    data-title="Coffee sachet"
                    data-category="Package design"
                >
                  <a
                      class="portfolio_popup"
                      href="#"
                      @click.prevent=""
                  >
                    <img src="/img/portfolio/ferntea/preview.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/ferntea/preview.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Coffee sachet</h3>
                  <span>Package design</span>
                </div>
              </div>
            </li>
            <li class="package grid-item">
              <div class="inner">
                <div
                    class="entry dizme_tm_portfolio_animation_wrap"
                    data-title="Сoals for hookah"
                    data-category="Package design"
                >
                  <a
                      class="portfolio_popup"
                      href="#"
                      @click.prevent=""
                  >
                    <img src="/img/portfolio/black_horse/preview.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/black_horse/preview.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Сoals for hookah</h3>
                  <span>Brand identity</span>
                </div>
              </div>
            </li>
            <li class="labels advertising grid-item">
              <div class="inner">
                <div
                    class="entry dizme_tm_portfolio_animation_wrap"
                    data-title="Beauty care kit"
                    data-category="Brand identity"
                >
                  <a
                      class="portfolio_popup"
                      href="#"
                      @click.prevent=""
                  >
                    <img src="/img/portfolio/everyday_beauty/preview.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/everyday_beauty/preview.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Beauty care kit</h3>
                  <span>Brand identity</span>
                </div>
              </div>
            </li>
            <li class="package grid-item">
              <div class="inner">
                <div
                    class="entry dizme_tm_portfolio_animation_wrap"
                    data-title="Gem bisquit"
                    data-category="Package design"
                >
                  <a
                      class="portfolio_popup"
                      href="#"
                      @click.prevent=""
                  >
                    <img src="/img/portfolio/lactor/preview.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/lactor/preview.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Gem bisquit</h3>
                  <span>Package design</span>
                </div>
              </div>
            </li>
            <li class="package grid-item">
              <div class="inner">
                <div
                    class="entry dizme_tm_portfolio_animation_wrap"
                    data-title="Smoked cheese snacks"
                    data-category="Package design"
                >
                  <a
                      class="portfolio_popup"
                      href="#"
                      @click.prevent=""
                  >
                    <img src="/img/portfolio/smoky_cheese/preview.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/smoky_cheese/preview.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Smoked cheese snacks</h3>
                  <span>Package design</span>
                </div>
              </div>
            </li>
            <li class="package grid-item">
              <div class="inner">
                <div
                    class="entry dizme_tm_portfolio_animation_wrap"
                    data-title="Puff corn"
                    data-category="Package design"
                >
                  <a
                      class="portfolio_popup"
                      href="#"
                      @click.prevent=""
                  >
                    <img src="/img/portfolio/puff_corn/preview.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/puff_corn/preview.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Puff corn</h3>
                  <span>Package design</span>
                </div>
              </div>
            </li>
            <li class="advertising package grid-item">
              <div class="inner">
                <div
                    class="entry dizme_tm_portfolio_animation_wrap"
                    data-title="Beauty care kit"
                    data-category="Package design"
                >
                  <a
                      class="portfolio_popup"
                      href="#"
                      @click.prevent=""
                  >
                    <img src="/img/portfolio/organic/preview.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/organic/preview.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Beauty care kit</h3>
                  <span>Package design</span>
                </div>
              </div>
            </li>
            <li class="package grid-item">
              <div class="inner">
                <div
                    class="entry dizme_tm_portfolio_animation_wrap"
                    data-title="Puttu breakfast"
                    data-category="Package design"
                >
                  <a
                      class="portfolio_popup"
                      href="#"
                      @click.prevent=""
                  >
                    <img src="/img/portfolio/harnutri/preview2.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/harnutri/preview2.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Puttu breakfast</h3>
                  <span>Package design</span>
                </div>
              </div>
            </li>

          </ul>
        </div>
      </div>
      <div class="brush_1 wow zoomIn" data-wow-duration="1s">
        <img src="/img/brushes/portfolio/1.png" alt="" />
      </div>
      <div class="brush_2 wow fadeInRight" data-wow-duration="1s">
        <img src="/img/brushes/portfolio/2.png" alt="" />
      </div>
    </div>
  </div>
  <MagnificPopUpVue
    :modal="modal"
    :close="closeModal"
    :name="name"
    :src="src"
  />
  <div :class="`${active === 1 ? '' : 'hidden_content'}`">
    <ModalBox :close="close">
      <div class="popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/portfolio/6.jpg"
            style="background-image: url('img/portfolio/6.jpg')"
          ></div>
        </div>
        <div class="portfolio_main_title">
          <h3>Global Evolution</h3>
          <span><a href="#">Detail</a></span>
          <div></div>
        </div>
        <div class="main_details">
          <div class="textbox">
            <p>
              We live in a world where we need to move quickly and iterate on
              our ideas as flexibly as possible.
            </p>
            <p>
              Mockups are useful both for the creative phase of the project -
              for instance when you're trying to figure out your user flows or
              the proper visual hierarchy - and the production phase when they
              phase when they will represent the target product. Building
              mockups strikes the ideal balance ease of modification.
            </p>
          </div>
          <div class="detailbox">
            <ul>
              <li>
                <span class="first">Client</span>
                <span>Alvaro Morata</span>
              </li>
              <li>
                <span class="first">Category</span>
                <span><a href="#">Detail</a></span>
              </li>
              <li>
                <span class="first">Date</span>
                <span>March 07, 2021</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="additional_images">
          <ul>
            <li>
              <div class="list_inner">
                <div class="my_image">
                  <img src="img/thumbs/4-2.jpg" alt="" />
                  <div
                    class="main"
                    data-img-url="img/portfolio/1.jpg"
                    style="background-image: url('img/portfolio/1.jpg')"
                  ></div>
                </div>
              </div>
            </li>
            <li>
              <div class="list_inner">
                <div class="my_image">
                  <img src="img/thumbs/4-2.jpg" alt="" />
                  <div
                    class="main"
                    data-img-url="img/portfolio/2.jpg"
                    style="background-image: url('img/portfolio/2.jpg')"
                  ></div>
                </div>
              </div>
            </li>
            <li>
              <div class="list_inner">
                <div class="my_image">
                  <img src="img/thumbs/4-2.jpg" alt="" />
                  <div
                    class="main"
                    data-img-url="img/portfolio/3.jpg"
                    style="background-image: url('img/portfolio/3.jpg')"
                  ></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ModalBox>
  </div>
</template>

<script>
import MagnificPopUpVue from "./popup/MagnificPopUp.vue";
import ModalBox from "./popup/ModalBox.vue";
export default {
  name: "PortfolioComponent",
  data() {
    return {
      activeNav: "1", // props
      name: "",
      src: "",
      modal: false,
      // Content
      active: 0,
    };
  },
  mounted() {
    let Isotope = require("isotope-layout");
    setTimeout(() => {
      let iso = new Isotope(".gallery_zoom", {
        itemSelector: ".grid-item",
        //    layoutMode: "fitRows",
        percentPosition: true,
        masonry: {
          columnWidth: ".grid-item",
        },
        animationOptions: {
          duration: 750,
          easing: "linear",
          queue: false,
        },
      });
      let filterFns = {
        // show if number is greater than 50
        numberGreaterThan50: function (itemElem) {
          let number = itemElem.querySelector(".number").textContent;
          return parseInt(number, 10) > 50;
        },
        // show if name ends with -ium
        ium: function (itemElem) {
          let name = itemElem.querySelector(".name").textContent;
          return name.match(/ium$/);
        },
      };
      // bind filter button click filter main funtion
      let filtersElem = document.querySelector(".portfolio_filter ul");
      filtersElem.addEventListener("click", function (event) {
        if (event.target.getAttribute("data-filter")) {
          let filterValue = event.target.getAttribute("data-filter");
          filterValue = filterFns[filterValue] || filterValue;
          iso.arrange({ filter: filterValue });
        }
      });
    }, 1000);
  },
  methods: {
    onClick(name, src) {
      this.name = name;
      this.src = src;
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
      this.name = "";
      this.src = "";
    },
    close() {
      this.active = 0;
    },
  },
  components: { MagnificPopUpVue, ModalBox },
};
</script>
