<template>
  <div class="dizme_tm_section" id="service">
    <div class="dizme_tm_services">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>Services</span>
          <h3>What do I offer to clients</h3>
          <p>
            The world of design is very diverse, and in every type of industrial or advertising design I can help you. These are just some of the typical areas of my projects.
          </p>
        </div>
        <div class="service_list">
          <ul>
            <li
              class="wow fadeInLeft"
              data-wow-duration="1s"
              @click.prevent="active = 1"
            >
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="/img/svg/service/anchor.svg" alt="" />
                  <img
                    class="back"
                    :src="`/img/brushes/service${dark ? '/dark' : ''}/1.png`"
                    alt=""
                  />
                </span>
                <div class="title">
                  <h3>Package Design</h3>
                  <span class="price">Starts from <span>120 EUR</span></span>
                </div>
                <div class="text">
                  <p>
                      Packaging is the most popular type of work I do, covering a wide range of materials and purposes. From cardboard boxes and plastic wrappers to souvenirs and promotional products, these are my most common and successful projects. I specialize in packaging for industrial goods, a variety of solutions for the confectionery industry, and tailored designs for the beauty industry.
                  </p>
                  <p>
                    <span class="price"><span>more...</span></span>
                  </p>
                </div>
                <a class="dizme_tm_full_link" href="#"></a>
                <img
                  class="popup_service_image"
                  src="/img/service/1.jpg"
                  alt=""
                />
              </div>
            </li>
            <li
              class="wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              @click.prevent="active = 2"
            >
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="/img/svg/service/physics.svg" alt="" />
                  <img
                    class="back"
                    :src="`/img/brushes/service${dark ? '/dark' : ''}/2.png`"
                    alt=""
                  />
                </span>
                <div class="title">
                  <h3>Label Design</h3>
                  <span class="price">Starts from <span>80 EUR</span></span>
                </div>
                <div class="text">
                  <p>
                    The label can rightfully be called the face of the product.
                    A successful design that emphasizes the strengths of a product and also takes into account the target audience can decide the fate of its sales.
                    Of course, most often this is a label for food products, drinks, and the confectionery industry.
                    But a significant part of my projects are labels for industrial products.
                  </p>
                  <p>
                    <span class="price"><span>more...</span></span>
                  </p>
                </div>
                <a class="dizme_tm_full_link" href="#"></a>
                <img
                  class="popup_service_image"
                  src="/img/service/2.jpg"
                  alt=""
                />
              </div>
            </li>
            <li
              class="wow fadeInLeft"
              data-wow-duration="1s"
              @click.prevent="active = 3"
            >
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="/img/svg/service/contact.svg" alt="" />
                  <img
                    class="back"
                    :src="`/img/brushes/service${dark ? '/dark' : ''}/3.png`"
                    alt=""
                  />
                </span>
                <div class="title">
                  <h3>Logo Design</h3>
                  <span class="price">Starts from <span>100 EUR</span></span>
                </div>
                <div class="text">
                  <p>
                    A great logo is super important for making your brand pop.
                    It's like the face of your brand, making it easy for folks to remember and recognize.
                    When your logo looks good, it gives off good vibes and makes people feel good about your brand.
                    Plus, it helps your brand stand out from the crowd and shows that you're pro and trustworthy.
                  </p>
                  <p>
                    <span class="price"><span>more...</span></span>
                  </p>
                </div>
                <a class="dizme_tm_full_link" href="#"></a>
                <img
                  class="popup_service_image"
                  src="/img/service/3.jpg"
                  alt=""
                />
              </div>
            </li>
            <li
              class="wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              @click.prevent="active = 4"
            >
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="/img/svg/service/web.svg" alt="" />
                  <img
                    class="back"
                    :src="`/img/brushes/service${dark ? '/dark' : ''}/4.png`"
                    alt=""
                  />
                </span>
                <div class="title">
                  <h3>Brand Identity Design</h3>
                  <span class="price">Starts from <span>200 EUR</span></span>
                </div>
                <div class="text">
                  <p>
                    Having a killer brand identity is like having a superpower for your business.
                    It's what makes you unforgettable in a sea of competitors.
                    Picture it like the personality of your brand – the vibes, the look, everything that makes you, well, you.
                    When your brand identity is on point, people remember you.
                  </p>
                  <p>
                    <span class="price"><span>more...</span></span>
                  </p>
                </div>
                <a class="dizme_tm_full_link" href="#"></a>
                <img
                  class="popup_service_image"
                  src="/img/service/4.jpg"
                  alt=""
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="brush_1 wow fadeInLeft" data-wow-duration="1s">
        <img src="/img/brushes/service/5.png" alt="" />
      </div>
      <div class="brush_2 wow zoomIn" data-wow-duration="1s">
        <img src="/img/brushes/service/6.png" alt="" />
      </div>
    </div>
  </div>
  <div :class="active == 1 ? '' : 'service_hidden_details'">
    <ModalBox :close="close">
      <div class="service_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/service/1.jpg"
            style="background-image: url('img/service/1.jpg')"
          ></div>
        </div>
        <div class="main_title">
          <h3>Package Design</h3>
          <span class="price">Starts from <span>120 EUR</span></span>
        </div>
        <div class="descriptions">
          <p>
            Packaging is the most popular type of work I do, covering a wide range of materials and purposes. From cardboard boxes and plastic wrappers to souvenirs and promotional products, these are my most common and successful projects. I specialize in packaging for industrial goods, a variety of solutions for the confectionery industry, and tailored designs for the beauty industry.
          </p>
          <p>
            Thanks to many years of experience in real polygraph production as a leading designer of a large printing house, I have practical skills about the specifics of packaging production, the specifics of using various types of structures, recommended materials and post-printing technologies. I am able to prepare not only files suitable for printing, but also files for selective varnishing, foil stamping, and relief embossing. Experience in production with multicolor printing machines allows me to create designs with a variety of special and additional inks (Pantone, Metallic, etc.) All this will be prepared in the form of a package of files, maximally prepared for printing, which reduces the cost of prepress services .
          </p>
          <p>
            I invite you to cooperate in creating the ideal packaging solution for your product!
          </p>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="active == 2 ? '' : 'service_hidden_details'">
    <ModalBox :close="close">
      <div class="service_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/service/2.jpg"
            style="background-image: url('img/service/2.jpg')"
          ></div>
        </div>
        <div class="main_title">
          <h3>Label Design</h3>
          <span class="price">Starts from <span>80 EUR</span></span>
        </div>
        <div class="descriptions">
          <p>
              A label can rightfully be called the face of a product. A well-designed label that highlights a product's strengths and resonates with its target audience can significantly impact its sales. While I often design labels for food products, beverages, and the confectionery industry, a significant portion of my work also involves labels for industrial products. With years of experience in label design, I've developed key principles that guide each project. The first step is always a thorough discussion with the client about the product's mission, vision, market positioning, strengths, and target audience. In my experience, the most successful projects result from close collaboration with the client and a willingness to explore new ideas.
          </p>
          <p>
              I would be delighted to offer my services in creating the perfect label for your product!
          </p>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="active == 3 ? '' : 'service_hidden_details'">
    <ModalBox :close="close">
      <div class="service_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/service/3.jpg"
            style="background-image: url('img/service/3.jpg')"
          ></div>
        </div>
        <div class="main_title">
          <h3>Logo Design</h3>
          <span class="price">Starts from <span>100 EUR</span></span>
        </div>
        <div class="descriptions">
          <p>
            A great logo is super important for making your brand pop.
            It's like the face of your brand, making it easy for folks to remember and recognize.
            When your logo looks good, it gives off good vibes and makes people feel good about your brand.
            Plus, it helps your brand stand out from the crowd and shows that you're pro and trustworthy.
            A snazzy logo sets the style for your brand and makes your marketing stuff more memorable.
            It's like a shortcut that tells people what your brand is all about.
            And if your logo is adaptable and works everywhere, from tiny business cards to huge billboards, that's a win.
            Over time, people start associating your logo with your brand, and that builds loyalty.
            So, investing in a stunning logo is a smart move for keeping your brand on top.
          </p>
          <p>
            Let's start this journey together!
          </p>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="active == 4 ? '' : 'service_hidden_details'">
    <ModalBox :close="close">
      <div class="service_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/service/4.jpg"
            style="background-image: url('img/service/4.jpg')"
          ></div>
        </div>
        <div class="main_title">
          <h3>Web Design</h3>
          <span class="price">Starts from <span>200 EUR</span></span>
        </div>
        <div class="descriptions">
          <p>
            Having a killer brand identity is like having a superpower for your business. It's what makes you unforgettable in a sea of competitors.
            Picture it like the personality of your brand – the vibes, the look, everything that makes you, well, you.
          </p>
          <p>
            When your brand identity is on point, people remember you. It's not just about a logo; it's the whole package – the colors, the fonts, the style.
            It's like a language your brand speaks, and it makes you stand out. Think of it as your brand's signature look that people recognize you from afar.
            Consistency is the name of the game.
            Whether it's your website, social media, or a product label, if it all looks and feels the same, you're building trust.
            It's like telling your customers, "Hey, we're the real deal, and we're here to stay."
          </p>
          <p>
            I will be happy to become a co-author of your successful start!
          </p>
        </div>
      </div>
    </ModalBox>
  </div>
</template>

<script>
import ModalBox from "./popup/ModalBox.vue";
export default {
  name: "ServiceCompoent",
  data() {
    return {
      active: 0,
    };
  },
  mounted() {
    let VanillaTilt = require("vanilla-tilt");
    VanillaTilt.init(document.querySelectorAll(".tilt-effect"), {
      maxTilt: 6,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      speed: 500,
      transition: true,
    });
  },
  methods: {
    close() {
      this.active = 0;
    },
  },
  components: { ModalBox },
  props: {
    dark: { type: Boolean },
  },
};
</script>
